@use 'config/globalStyle.scss' as gs;

@font-face {
  font-family: Oxanium;
  src: url('./fonts/Oxanium-Regular.ttf')
}

*{
  /* font:15px/21px 'Oxanium', sans-serif; height:auto; color:#d3ccba; */
  font:15px/21px 'Oxanium', sans-serif; height:auto; color:gs.$main-color;
  /* font:15px/21px 'Arial', sans-serif; height:auto; color:#c2eae5; */
  /* background-color: #000306; */
  background: transparent;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}

body{
  // background-attachment: fixed;
}