@use '../../config/globalStyle.scss' as gs;

.AniTranslation {
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 2000;
  margin: 0;
  padding: auto auto;
  top: 0;
  left: 0;
  display: table;
  opacity: 0.7;
}

.Notification {
  display:table-cell;
  background-color: gs.$active-color;
  vertical-align: middle;
  color:gs.$background-color;
  font-size: 9em;
  line-height: 1em;
  font-weight: 500;
  text-align: center;
  z-index: 2001;
  top: 50%;
  opacity: 1;
  text-shadow: gs.$background-color 5px 2px 3px;
  user-select: none;
  animation: increaseOpacity 0.3s linear;
  animation-iteration-count: 1;
  will-change: transform, opacity;
}

@media screen and (min-width:900px) and (max-width:1699px){
  .Notification {
    font-size: 6em;
  }
}

@media screen and (min-width:200px) and (max-width:899px){
  .Notification {
    font-size: 4em;
  }
}

@keyframes increaseOpacity {
  0% {
    opacity:0.5;
  }
  
  100% {
    opacity: 1;
  }
}
