@use '../../config/globalStyle.scss' as gs;

.MainMenu {
  grid-area: MainMenu;
  /* background-color: #0e0e0e; */
  margin: 5px;
  padding: 5px;
  height: 100%;

}

.show {
  display: block;
}

.hide {
  display: none;
}

@media screen and (min-width:900px) and (max-width:1699px){
  .MainMenu {
  /* background-color: gs.$background-color; */
  }
}

@media screen and (min-width:200px) and (max-width:899px){
  .MainMenu {
    /* display: none; */
    position: fixed;
    height: 100%;
    top: 0%;
    width: fit-content;
    left: 0%;
    opacity: 0.9;
    z-index: 1000;
    /* box-shadow: #fb024c -2px -2px 10px; */
    box-shadow: gs.$main-color -2px -2px 10px;
    background-color: gs.$background-color;
    margin: 0px;
    padding: 0px;
    padding-top: 10%;
  }
}