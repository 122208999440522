// original
$background-color: #000306;
$background-color-transparent: #00030680;
$active-color: #ff004c;
$active-color-transparent: #fb024d49;
$main-color: #c2eae5;

// mono-stim-coffee
// $background-color: #111311;
// $background-color-transparent: #11131180;
// $active-color: #ece4ce;
// $active-color-transparent: #ece4ce49;
// $main-color: #ece4ce;

// mono-crimson-red
// $background-color: #111311;
// $background-color-transparent: #11131180;
// $active-color: #971c1c;
// $active-color-transparent: #971c1c49;
// $main-color: #971c1c;

// mono-grayish
// $background-color: #111311;
// $background-color-transparent: #11131180;
// $active-color: #e4e4e4;
// $active-color-transparent: #e4e4e449;
// $main-color: #e4e4e4;

// mono-white
// $background-color: #000000;
// $background-color-transparent: #00000080;
// $active-color: #ffffff;
// $active-color-transparent: #ffffff49;
// $main-color: #ffffff;

// mono-black
// $background-color: #ffffff;
// $background-color-transparent: #ffffff80;
// $active-color: #000000;
// $active-color-transparent: #00000049;
// $main-color: #000000;

// crimson-red
// $background-color: #000000;
// $background-color-transparent: #00000080;
// $active-color: #7e0000;
// $active-color-transparent: #7e000049;
// $main-color: #ffffff;

// mono-black
// $background-color: #ffffff;
// $background-color-transparent: $background-color+'80';
// $active-color: #000000;
// $active-color-transparent: $active-color+'49';
// $main-color: #000000;

// original
$background-color: #000306;
$background-color-transparent: #00030680;
$active-color: #ff004c;
$active-color-transparent: #fb024d49;
$main-color: #c2eae5;