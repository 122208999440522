.ContentArea {
  grid-area: ContentArea;
  /* background-color: #0e0e0e; */
  /* margin: 5px; */
  padding: 10px;
}


.Content>h2 {
  font-size: 2em;
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 10px;

}

.Content h2:nth-child(1) {
  margin-top: 25px;
}

.Stack2Table {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media screen and (min-width:900px) and (max-width:1699px){
  .Stack2Table {
    grid-template-columns: 1fr 1fr;
  }
}

.Stack1Table {
  display: grid;
  grid-template-columns: 1fr;
}

.IframeContainer {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* height: 50vh; */
    /* margin: 0; */
    /* padding: 20px; */
    margin: 20px;

}

#IframeTerminal {
  width: 60%;
  height: 40vh;
  border: none;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(255, 0, 76, 0.6);
  background-color: rgba(0, 0, 0, 0.5);
}

@media screen and (min-width:200px) and (max-width:899px){
  .Stack2Table {
    grid-template-columns: 1fr;
  }

  #IframeTerminal {
    width: 90vw;
    height: 90vh;
  }
}