@use '../../config/globalStyle.scss' as gs;

.ContentItem {
  display: grid;
  grid-template: "Title Title Title" 50px
                 "Image Description GoLive" 120px
                 "Image Notes GoLive" 50px / 2fr 3fr 1fr;
  margin: 20px;
  padding: 10px;
  border: 2px solid gs.$main-color;
  // text-shadow: gs.$main-color -2px -2px 3px;
  transition: background 0.1s, text-shadow 0.1s;
  /* background-color: #000306; */
}

.ContentItem:hover {
  text-shadow: none;
  background: gs.$background-color-transparent;
}

.ContentItem:hover img {
  filter: blur(0px)

}

.Title {
  grid-area: Title;
  font-size: 2em;
  padding-top: 10px;
  font-weight: 600;
}

.Image {
  grid-area: Image;
  text-align: center;
}

.Image img {
  height: 150px;
  padding: 5px;
  margin: 0 auto;
  filter: blur(2px);
  filter: grayscale(100%);
  /* box-shadow: #fb024c 0 0 10px; */
  /* box-shadow: gs.$main-color 0 0 3px; */
}

.Description {
  grid-area: Description;
  font-size: 1.2em;
  line-height: 1.8em;
  text-align: justify;
  padding-right: 20px;
}

.Notes {
  grid-area: Notes;
  margin-top: 20px;
}

.GoLive div{
  grid-area: GoLive;
  height:60px;
  line-height: 60px;
  font-size: 1.2em;
}

@media screen and (min-width:900px) and (max-width:1699px){
  .ContentItem {
    display: grid;
    grid-template: "Title Title Title" auto
                   "Image Description GoLive" fit-content(100%)
                   "Image Notes GoLive" auto / 2fr 3fr 1fr;
  }

  .Title {
    padding: 10px;
    line-height: 110%;
  }

  .Description {
    padding-left: 10px;
    padding-right: 20px;
    padding-bottom: 20px;
    font-size: 1em;
  }

  .Notes {
    padding-left: 10px;
    line-height: 150%;
    padding-bottom: 10px;
  }
}


@media screen and (min-width:200px) and (max-width:899px){
  .ContentItem {
    display: grid;
    grid-template: "Title" fit-content(100%)
                   "Image" fit-content(100%)
                   "GoLive" 100px
                   "Description" fit-content(100%)
                   "Notes"  auto / 1fr;
    margin: 0px;       
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border: 2px solid gs.$main-color;
    // text-shadow: gs.$main-color -2px -2px 3px;
  }

  .Title {
    margin-bottom: 10px;
    font-size: 1.5em;
    line-height: 1.5em;
  }

  .Image img {
    height: 150px;
  }

  .Description {
    margin-bottom: 10px;
    font-size: 1em;
    padding-right: 0px
  }

  .Notes {
    line-height: 150%;
    padding-bottom: 5px;
  }

  .GoLive div{
    margin: 0 auto;
  }
}