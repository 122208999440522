@use '../../config/globalStyle.scss' as gs;

.TopBar {
  grid-area: TopBar;
  /* background-color: #0e0e0e; */
  margin: 0 auto;
  padding: 10px;
  display: grid;
  grid-template-columns: 70% 30%;
  user-select: none;
  position: fixed;
  width: 100%;
  text-align: center;
  z-index: 2000;
  top: 0px;
  left: 0px;
  background: gs.$background-color-transparent;
}

.TopBar h1{
  font-size: 30px;
  font-weight: 800;
  // text-shadow: gs.$main-color -2px -2px 3px;
  user-select: none;
  text-align: left;
}

.Languages {
  text-align: right;
  padding-right: 20px;
}

.Languages, .Languages span {
  font-size: 30px;
  cursor: pointer;
}

.notSelected:hover{
  color: gs.$active-color;
  /* border: 2px solid gs.$active-color; */
  // text-shadow: gs.$active-color 2px 2px 5px;
}

.selected {
  /* border: 2px solid gs.$active-color; */
  color: gs.$background-color;
  background-color: gs.$active-color;
  user-select: none;
  padding: 5px
}

@media screen and (min-width:900px) and (max-width:1699px){
  .TopBar {
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0 auto;
  }

  .TopBar h1{
    font-size: 15px;
  }

  .Languages, .Languages span {
    font-size: 15px;
  }
}

@media screen and (min-width:200px) and (max-width:899px){
  .TopBar {
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0 auto;
  }

  .TopBar h1{
    font-size: 10px;
  }

  .Languages, .Languages span {
    font-size: 15px;
  }
}
