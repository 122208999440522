@use '../../config/globalStyle.scss' as gs;

.MenuIcon {
  padding: 5px;
  opacity:0.9;
  display: none;
  cursor: pointer;
  user-select: none;
  border: 2px solid gs.$main-color;
  // text-shadow: gs.$main-color -2px -2px 3px;
  background-color: gs.$background-color;
}

.MenuIcon:hover {
  color: gs.$active-color;
  border: 2px solid gs.$active-color;
  text-shadow: none;
}

@media screen and (min-width:900px) and (max-width:1699px){
  .MenuIcon {
  }
}

@media screen and (min-width:200px) and (max-width:899px){
  .MenuIcon {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: block;
  }
}