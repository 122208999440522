@use '../../config/globalStyle.scss' as gs;

.Switch {
  border: 2px solid gs.$main-color;
  margin: 10px;
  height: 50px;
  padding: 10px;
  // text-shadow: gs.$main-color -2px -2px 3px;
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
  width: 170px;
  user-select: none;
  transform: background 0.1s;
}

.SwitchSelected {
  border: 2px solid gs.$active-color;
  color: gs.$background-color;
  background-color: gs.$active-color;
  margin: 10px;
  height: 50px;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
  width: 170px;
  // text-shadow: gs.$background-color 4px 4px 5px;
  user-select: none;
}

.Switch:hover {
  color: gs.$active-color;
  border: 2px solid gs.$active-color;
  // text-shadow: gs.$active-color 2px 2px 5px;
  background: gs.$background-color-transparent;
}

a {
  text-decoration: none;
}

@media screen and (min-width:900px) and (max-width:1699px){
  .Switch {
  }
}


@media screen and (min-width:200px) and (max-width:899px){
  .Switch {
    /* display: none; */
    width: 170px;
  }

  .SwitchSelected {
    /* display: none; */
    width: 170px;
  }
}

