/* *{border: 1px red solid;} */

.App {
  display: grid;
  grid-template: 
                 "MainMenu ContentArea ." auto
                 "Credit Credit Credit" 40px / 300px 1fr 300px;
  margin: 5px;
  padding-top: 20px;
  min-height: 100%;
  overflow: hidden;
}

@media screen and (min-width:900px) and (max-width:1699px){
  .App {
    grid-template: 
                   "MainMenu ContentArea" auto
                   "Credit Credit" 40px / 250px 1fr;
  }
}

@media screen and (min-width:200px) and (max-width:899px){
  .App {
    grid-template: 
                   "ContentArea" auto
                   "Credit" 40px / 1fr;
    padding-top: 10px;
    
  }
}