@use '../../config/globalStyle.scss' as gs;

.Credit {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-size: 0.9em;
  // text-shadow: gs.$main-color -2px -2px 3px;
  grid-area: Credit;
}

@media screen and (min-width:900px) and (max-width:1699px){
  .Credit {
    
  }
}

@media screen and (min-width:200px) and (max-width:899px){
  .Credit {
  
  }
}