@use '../../config/globalStyle.scss' as gs;

/* .AniBackground {
  background-color: gs.$background-color;
  height: 100%;
} */

.distort {
  animation: distort 10s ease-out;
  animation-iteration-count: infinite; 
  opacity: 1;
}

@keyframes distort {
  0% {
    transform: scaleX(0.9) skewY(0deg);
  }

  50% {
    transform: scaleX(1.1) skewY(30deg);
  }
  
  100% {
    transform: scaleX(0.9) skewY(0deg);
  }
}

.pulsate {
  animation: pulsate 5s ease-out;
  animation-iteration-count: infinite; 
  opacity: 1;
}

@keyframes pulsate {
  0% { 
      color: gs.$active-color;
  }
  50% { 
      color: gs.$active-color-transparent;
  }
  100% { 
      color: gs.$active-color; 
  }
}

.artContainer {
  background-color: gs.$background-color;
  position: fixed;
  width: 100%;
  height: 120%;
  text-align: center;
  z-index: -10;
  margin: 0;
  padding: auto auto;
  top: 0;
  left: 0;
  display: table;
  overflow: hidden;
  /* opacity: 0.7; */
}

/* @keyframes animation {
  0% {
    transform: scale(1,1) skewY(0deg);
    color: gs.$active-color;
  }

  50% {
    transform: scale(0.7,0.7) skewY(30deg);
    color: #fb024d40;
  }
  
  100% {
    transform: scale(1,1) skewY(0deg);
    color: gs.$active-color; 
  }
} */

@keyframes animation {
  0% {
    transform: scale(10,10);
    color: gs.$active-color;
  }

  50% {
    transform: scale(1,1);
    color: #fb024d40;
  }
  
  100% {
    transform: scale(10,10);
    color: gs.$active-color; 
  }
}

/* @keyframes animation {
  0% {
    transform: scale(10,10) skewY(180deg);
    color: gs.$active-color40;
  }

  49% {
    transform: scale(0.8,0.8) skewY(0deg);
    color: #fb024d;
  }

  51% {
    transform: scale(0.8,0.8) skewY(0deg);
    color: #fb024d;
  }
  
  100% {
    transform: scale(10,10) skewY(180deg);
    color: gs.$active-color40; 
  }
} */

/* .animation {
  animation: animation 60s linear;
  animation-iteration-count: infinite; 
} */

.animation {
  animation: animation 30s ease-in-out;
  animation-iteration-count: infinite; 
}